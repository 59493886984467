* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* navbar section */

.navbar {
  position: sticky !important;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #1c1c1c; 
  padding: 1rem 2rem;
  font-family: "pretendard", sans-serif;
   font-weight: 600;
   font-style: normal;
   border-bottom: solid 2px #daddd8;
}


.navbar-brand {
  font-family: "pretendard", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.navbar .navbar-brand {
  color: #daddd8; 
  cursor: pointer; 
}
.navbar .navbar-brand:hover {
  color: #daddd8;  
}

 .nav-item a {
  text-decoration: none;
  color: inherit; 
  color: #daddd8;
} 

.navbar .navbar-collapse {
  justify-content: flex-end;
}


.navbar .navbar-nav .nav-item .nav-link {
  color: #daddd8; 
  margin-right: 2rem;
  text-decoration: none;
}

.navbar .navbar-nav .nav-item .nav-link:hover {
  color: #eef0f2; 
}

/* .navbar .navbar-nav .nav-item .nav-link.active {
  color: #ecebe4; 
  border-bottom: 2px solid #eef0f2; 
}
*/
/* landing section */

.landing-section {
  min-height: 75vh;
  height: auto; 
  background-image: url('./assets/boatt_establishing_shot_during_golden_hour__Douglass_Fir_Forest_44a0de50-fcf3-4436-a0c4-dca4b2d26adf.png');
  background-size: cover; 
  background-position: center; 
  background-blend-mode: overlay; 
  background-color: rgba(0, 0, 0, .55); 
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: solid 2px #daddd8;
}

.portrait {
  width: 250px;
  height: auto;
  border-color: #ecebe4;
  border-width: 5px;
  border-style: solid;
  border-radius: 20px;
}

.overlay-content {
  text-align: left;
  color: #ecebe4;
  margin-left: 20px;
  font-family: "pretendard", sans-serif;
   font-weight: 600;
   font-style: normal;
}

.overlay-content .first-name, 
.overlay-content .last-name {
  display: block;
  font-size: 4rem; 
  font-weight: bold;
}

.overlay-content .first-name {
  margin-bottom: -20px;
}

.overlay-content p {
  font-size: 1.5rem; 
  margin-bottom: 20px;
}

.overlay-content a {
  margin-top: 10px;
}
.resumeButton {
  padding: 10px;
  border: solid 5px #daddd8;
  border-radius: 20px;
  background-color: #ecebe4;
  color: #1c1c1c;
  font-family: "pretendard", sans-serif;
   font-weight: 700;
   font-style: normal;
   text-decoration: none;
   margin-top: 10px;
}

.resumeButton:hover {
  background-color: #daddd8;
} 

/* About me / skills sections */

.sections-container {
  display: flex;      
  justify-content: space-between; 
  background-color:#1c1c1c;
  color:#daddd8;
  border-bottom: solid 2px #daddd8;
}

.about-me-section, .skills-section {
  flex: 1;            
  padding: 2em;      
  align-items: center;
  
}

.about-me-section h2, .skills-section h2 {
  text-align: center;
  font-family: "pretendard", sans-serif;
   font-weight: 500;
   font-style: normal;
}

.skills-section img {
  width: 50px; 
  height: auto;
  margin: 0 20px;  
}

.list {
  list-style-type: none; 
  margin: 25px;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
 font-size: 25px;
 text-align: center;
 font-family: "pretendard", sans-serif;
 font-weight: 300;
 font-style: normal;
}

.listFlex {
  margin-right: 0px;
  margin-top: 5px;
  background-color: #ecebe4;
  border: solid 5px #daddd8;
  border-radius: 20px;
}

.listFlex ul {
  padding-left: 0px;
}

/* Porfolio Section */ 

.portfolio-section {
  background-color: #1c1c1c;
  padding: 2em 2em 2em 2em;
  text-align: center; 
  border-bottom: solid 2px #daddd8;
}

.portfolio-section h2 {
  margin-bottom: 1em; 
  color: #ecebe4; 
  font-family: "pretendard", sans-serif;
 font-weight: 350;
 font-style: normal;
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.project-wrapper {
  flex: 0 1 calc(50% - 2%); 
  margin: 1%;  
  min-height: auto;
}

.project {
  display: flex;
  align-items: center;
  background-color: #ecebe4;
  color: #1c1c1c;
  border-radius: 20px;
  border: 5px solid #daddd8;
  margin-bottom: 20px; 
}

.project-image {
  max-width: 50%; 
  height: auto;
  margin-right: 1em;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
}

.project-image img {
  height: 150px;
  width: auto;
}

.project-content {
  flex: 1;
  background-color: #ecebe4;
  padding: 1em;
  color: #1c1c1c;
}

.project-content h3 {
  font-size: 1.5em;
  margin-bottom: 0.5em;
  font-family: "pretendard", sans-serif;
 font-weight: 350;
 font-style: normal;
}

.project-content p {
  font-family: "pretendard", sans-serif;
 font-weight: 300;
 font-style: normal;
}

/* buttons styling */
.project-buttons {
  display: flex;
  justify-content: center; 
  gap: 10px; 
  margin-top: 20px; 
}

.btn-live, .btn-repo {
  padding: 10px 15px;
  border: solid 5px #daddd8;
  border-radius: 20px; 
  text-decoration: none;
  text-align: center;
  background-color: #ecebe4; 
  color: #1c1c1c; 
  transition: background-color 0.3s ease;
  font-family: "pretendard", sans-serif;
 font-weight: 350;
 font-style: normal;
}

.btn-live:hover, .btn-repo:hover {
  background-color: #daddd8; 
}

/* Contact Section */

.contact-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  background-color: #1c1c1c;
  color: #ecebe4;
}

.contact-section h2 {
  font-family: "pretendard", sans-serif;
 font-weight: 350;
 font-style: normal;
}

.contact-form {
  max-width: 1000px;
  max-height: 400px;
  width: 100%;
  padding: 1em;
  background-color: #ecebe4; 
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  color:#1c1c1c;
  border: solid 5px #daddd8;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.1em;
}

label {
  margin-bottom: 0.1em;
}

input, textarea {
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  width: 100%;
}

button[type="submit"] {
  padding: 0.8em 1.5em;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #555;
}

.error-message {
  color: red;
  font-size: 0.8em;
  margin-top: 0.2em;
}

/* media queries */

@media (max-width: 1290px) {
  .projects-container {
    flex-direction: column;
  }
  .project-image {
    max-width: 30%;
  }
  
}

@media (max-width: 1162px) {
  .sections-container {
    flex-direction: column;
  }
  .about-me-section {           
    padding: 1em; 
    padding-top: 2em;     
  }
  .skills-section {
    padding: .5em;
    padding-bottom: 2.5em;
  }
}

@media (max-width: 991px) {
  .navbar-toggler-icon {
    filter: invert(1); 
    background-color: #ecebe4; 
  
  }
}

@media (max-width: 875px) {
  .project-image {
    max-width: 40%;
  }
}

@media (max-width: 745px) {
  .landing-section {
    flex-direction: column;
  }

  .portrait {
    margin-bottom: 20px;  
    align-self: center;   
  }

  .overlay-content {
    text-align: center;
    margin-left: 0;
  }
}

@media (max-width: 650px) {
  .project-image {
    background-size: cover;
    height:auto;
  }
  .skills-section {
    padding: 2em;
  }
  .skills-section img {
    width: 25px; 
    height: auto;
    margin: 10px;  
  }
  .project-wrapper {
    margin: 2%;
  }
}

@media (max-width: 580px) {

  .project-wrapper {
    flex: 0 1 calc(100% - 2%);  
  }

  .project {
    flex-direction: column; 
  }

  .project-image {
    max-width: 100%;  
    margin-right: 0;  
    border-top-left-radius: 20px;
    border-top-right-radius: 20px; 
    border-bottom-left-radius: 0;  
  }

  .project-content {
    padding-top: 20px;  
  }
}

@media (max-width: 463px) {
  .skills-section img {
    width: 20px; 
    height: auto;
    margin: 10px;  
  }
  .portrait {
    width: 200px;
    height: auto;
  }
  .overlay-content .first-name, 
.overlay-content .last-name {
  display: block;
  font-size: 3rem; 
  font-weight: bold;
}
  .resumeButton {
    font-size: 1rem;
  }
}